import { Bubble } from './bubble';

/**
 * ToastOptions stores configuration for Call-to-Action text shown next to the
 * placeholder avatar.
 */
export interface ToastOptions {
  message: string;
  placeholderTimeout: number;
}

/**
 * AvatarOptions holds configuration for the placeholder avatar.
 */
export interface AvatarOptions {
  toast?: ToastOptions;
}

/**
 * Bot connection options.
 */
export interface BotOptions {
  moonkin: {
    url: string;
    username: string;
    password: string;
  };
}

/**
 * Chat options.
 */
export interface Options {
  placeholder: {
    avatar: AvatarOptions;
  };
  userStyle: {
    url: string;
  };
  bot: BotOptions;
  placeholderStyles: CustomStyles;
  botAvatar: string;
  multiClientSupport: boolean;
}

/**
 * Config data from the server.
 */
export interface CustomConfig {
  kauzChatGreeting: string;
  kauzChatPlaceholderGreeting: number;
  kauzChatUserstyleURL: string;
  placeholderStyles: CustomStyles;
  botAvatar: string;
}

export interface CustomStyles {
  'mat-primary-500': string;
  'mat-primary-contrast-500': string;
  'mat-secondary-500': string;
  'mat-secondary-contrast-500': string;
  'chatbot-background-color': string;
}

/**
 * Toast shows a speech bubble.
 */
class Toast {
  element: HTMLElement;
  private timeoutID: number;
  private placeholderTimeoutValue: number;

  constructor(options: ToastOptions) {
    this.element = Template.defaultToastTemplate(options.message);
    this.placeholderTimeoutValue = options.placeholderTimeout;
    this.init();
  }

  init(): void {
    this.element.classList.remove('invisible');

    this.timeoutID = window.setTimeout(() => {
      this.element.classList.add('invisible');
    }, this.placeholderTimeoutValue);

    window.addEventListener('kauz.chat.open', (_event: CustomEvent) => {
      this.element.classList.add('invisible');
    });
    window.addEventListener(
      'kauz.avatar.enter',
      (_event: CustomEvent<Bubble>) => {
        window.clearTimeout(this.timeoutID);
        this.element.classList.remove('invisible');
      },
    );
    window.addEventListener('kauz.avatar.leave', (_event: CustomEvent) => {
      this.element.classList.add('invisible');
    });
  }

  getElement(): HTMLElement {
    return this.element;
  }
}

/**
 * Avatar comprises a chatbot placeholder avatar image and a speech bubble.
 */
export class Avatar {
  public element: HTMLElement;
  private image: HTMLElement;
  private options: AvatarOptions;
  private toast: Toast;

  constructor(options: AvatarOptions) {
    this.element = Template.defaultAvatarTemplate();
    this.options = this.applyDefaultOptions(options);

    this.init();
  }

  private applyDefaultOptions(options: AvatarOptions): AvatarOptions {
    return Object.assign({}, {}, options);
  }

  private init(): void {
    if (this.options.toast) {
      this.toast = new Toast(this.options.toast);
      this.element.appendChild(this.toast.element);
    }

    this.image = document.createElement('div');
    this.image.classList.add('bot', 'avatar');

    this.element.appendChild(this.image);

    window.addEventListener('kauz.chat.open', (_: CustomEvent) => {
      this.element.classList.add('invisible');
    });
    window.addEventListener('kauz.chat.close', (_: CustomEvent) => {
      this.element.classList.remove('invisible');
    });

    this.registerHandlers();
  }

  private registerHandlers(): void {
    this.image.addEventListener('mouseenter', (_: MouseEvent) => {
      window.dispatchEvent(
        new CustomEvent('kauz.avatar.enter', { detail: this }),
      );
    });
    this.image.addEventListener('mouseleave', (_: MouseEvent) => {
      window.dispatchEvent(
        new CustomEvent('kauz.avatar.leave', { detail: this }),
      );
    });
    this.image.addEventListener('click', (_: MouseEvent) => {
      window.dispatchEvent(new CustomEvent('kauz.chat.open', { detail: this }));
    });
  }

  getElement(): HTMLElement {
    return this.element;
  }
}

/**
 * Template holds HTML elements used by chatbot placeholder UI.
 */
class Template {
  static defaultAvatarTemplate(): HTMLElement {
    const element = document.createElement('div');
    element.id = 'placeholder';

    return element;
  }

  static defaultToastTemplate(message: string): HTMLElement {
    const element = document.createElement('div');
    element.id = 'greeting';

    element.innerHTML = message;

    return element;
  }
}
