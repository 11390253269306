export interface BubbleOptions {
  url: string;
}

/**
 * Bubble is a placeholder for the main chatbot panel. The placeholder consists
 * of an overlaid avatar image. Activating the avatar will load the main
 * conversation pane application.
 *
 * Optionally, a Call-to-Action text is shown in a speech bubble next to the
 * avatar.
 */
export class Bubble {
  public element: HTMLElement;
  private iframe: HTMLIFrameElement;
  private options: BubbleOptions;
  private initialized = false;
  private intervalId;
  private baseHost: string;

  constructor(options: BubbleOptions) {
    this.element = Template.defaultBubbleTemplate();
    this.element.classList.add('invisible');
    this.options = this.applyDefaultOptions(options);

    const pathArray = this.options.url.split('/');

    this.baseHost = `${pathArray[0]}//${pathArray[2]}`;

    window.addEventListener('kauz.chat.open', (_event: CustomEvent) => {
      if (!this.initialized) {
        this.init();
      }
      this.open();
    });

    window.addEventListener('kauz.chat.close', (_event: CustomEvent) => {
      this.close();
    });

    window.addEventListener('resize', (_: UIEvent) => {
      window.dispatchEvent(
        new CustomEvent('kauz.chat.resize', { detail: this }),
      );
    });

    this.element.addEventListener('mouseenter', (_: MouseEvent) => {
      window.dispatchEvent(
        new CustomEvent('kauz.chat.enter', { detail: this }),
      );
    });
    this.element.addEventListener('mouseleave', (_: MouseEvent) => {
      window.dispatchEvent(
        new CustomEvent('kauz.chat.leave', {
          detail: this,
        }),
      );
    });

    window.addEventListener('message', (e) => {
      if (e.origin !== this.baseHost) {
        return;
      }

      switch (e.data.event || '') {
        case 'kauz.chat.iframeInitEnd':
          window.clearInterval(this.intervalId);
          break;
        case 'kauz.chat.close':
          window.dispatchEvent(
            new CustomEvent('kauz.chat.close', { detail: {} }),
          );
          break;
        case 'kauz.chat.liveChatOpen':
          window.dispatchEvent(
            new CustomEvent('kauz.chat.liveChatOpen', { detail: {} }),
          );
          break;
        case 'kauz.link.open':
          window.dispatchEvent(
            new CustomEvent('kauz.link.open', {
              detail: { url: e.data.url },
            }),
          );
          break;
        case 'kauz.phonelink.open':
          window.dispatchEvent(
            new CustomEvent('kauz.phonelink.open', {
              detail: { url: e.data.url },
            }),
          );
          break;
        default:
          break;
      }
    });
  }

  private init(): void {
    this.element.appendChild(this.createFrame());
    this.initialized = true;
  }

  private applyDefaultOptions(options: BubbleOptions): BubbleOptions {
    return Object.assign({}, {}, options);
  }

  private createFrame(): HTMLElement {
    const div = document.createElement('div');
    this.iframe = document.createElement('iframe');
    this.iframe.setAttribute('src', this.options.url);
    this.iframe.setAttribute('allow', 'autoplay; fullscreen');
    div.appendChild(this.iframe);

    this.intervalId = window.setInterval(() => {
      this.iframe.contentWindow.postMessage(
        'kauz.chat.inIframe',
        this.baseHost,
      );
    }, 500);

    return div;
  }

  open(): void {
    this.element.classList.remove('invisible');
  }

  close(): void {
    this.element.classList.add('invisible');
  }

  getElement(): HTMLElement {
    return this.element;
  }
}

/**
 * Template holds HTML elements used by chatbot placeholder UI.
 */
class Template {
  static defaultBubbleTemplate(): HTMLElement {
    const element = document.createElement('div');
    element.id = 'chat-ui';
    element.classList.add('invisible');

    return element;
  }
}
