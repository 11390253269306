import { Avatar, CustomConfig, Options } from './avatar';

import { Bubble } from './bubble';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultCss = require('./placeholder.css');

function makeRequest(
  url: URL,
  method?: string,
  username?: string,
  password?: string,
): Promise<string | CustomConfig> {
  const request = new XMLHttpRequest();

  return new Promise(function (resolve, reject) {
    request.onreadystatechange = function (): void {
      if (request.readyState !== 4) {
        return;
      }
      if (request.status >= 200 && request.status < 300) {
        resolve(request.responseText);
      } else {
        reject({
          status: request.status,
          statusText: request.statusText,
        });
      }
    };
    request.open(method || 'GET', url.toString(), true);

    if (username && password) {
      request.setRequestHeader(
        'Authorization',
        `Basic ${btoa(`${username}:${password}`)}`,
      );
    }

    request.send();
  });
}

function setupBubble(options: Options, baseUrl: URL, embedUrl: URL): void {
  // We are using a shadow root so that there are no clashes between
  // the customer’s and our own CSS.
  const wrapper = document.createElement('div');
  wrapper.id = 'kauz-root';
  wrapper.attachShadow({ mode: 'open' });
  document.body.appendChild(wrapper);

  const defaultStyle = document.createElement('style');
  defaultStyle.type = 'text/css';
  defaultStyle.innerHTML = defaultCss;
  wrapper.shadowRoot.appendChild(defaultStyle);

  const styleUrl = new window.URL(options.userStyle.url, baseUrl);
  const link = document.createElement('link');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('href', styleUrl.toString());

  const customStyleLoaded = new Promise((res, rej) => {
    link.onload = res;
    link.onerror = rej;
    wrapper.shadowRoot.appendChild(link);
  });

  customStyleLoaded
    .catch(() => {
      console.log('failed to load user-supplied CSS');
    })
    .finally(() => {
      const avatar = new Avatar(options.placeholder.avatar);
      const bubble = new Bubble({ url: embedUrl.toString() });
      wrapper.shadowRoot.appendChild(avatar.element);
      wrapper.shadowRoot.appendChild(bubble.element);
    });

  if (options.placeholderStyles) {
    const style = document.createElement('style');
    style.appendChild(
      document.createTextNode(`
        #placeholder {
          ${
            options.placeholderStyles['mat-primary-500']
              ? `--mat-primary-500: ${options.placeholderStyles['mat-primary-500']};`
              : ''
          }
          ${
            options.placeholderStyles['mat-primary-contrast-500']
              ? `--mat-primary-contrast-500: ${options.placeholderStyles['mat-primary-contrast-500']};`
              : ''
          }
          ${
            options.placeholderStyles['mat-secondary-500']
              ? `--mat-secondary-500: ${options.placeholderStyles['mat-secondary-500']};`
              : ''
          }
          ${
            options.placeholderStyles['mat-secondary-contrast-500']
              ? `--mat-secondary-contrast-500: ${options.placeholderStyles['mat-secondary-contrast-500']};`
              : ''
          }
          ${
            options.placeholderStyles['chatbot-background-color']
              ? `--chatbot-background-color: ${options.placeholderStyles['chatbot-background-color']};`
              : ''
          }
          ${
            options.botAvatar
              ? `.bot.avatar {
                    background-image: url("${options.botAvatar}");
                  }`
              : ''
          }
        }`),
    );
    wrapper.shadowRoot.appendChild(style);
  }
}

// The placeholder runs under the customer’s URL. In order to get the
// configuration file running at our own URL without any explicit configuration
// on the customer’s side, we rely on the source URL this script is loaded from.

function initChat(currentScript) {
  const src = new window.URL(currentScript, window.location.href);
  const baseUrl = new window.URL('..', src);
  const embedUrl = new window.URL('..', src);
  embedUrl.search = src.search;
  const configUrl = new window.URL('assets/config/config.json', baseUrl);

  const requestConfig = makeRequest(configUrl);
  requestConfig
    .then((config: string) => {
      const options = JSON.parse(config) as Options;

      const configServerUrl = new window.URL(
        `${options.bot.moonkin.url}/api/v4/configurations/chat`,
      );
      configServerUrl.search = src.search;

      const group = configServerUrl.searchParams.get('group');

      if (options.multiClientSupport && group) {
        makeRequest(
          configServerUrl,
          'GET',
          options.bot.moonkin.username,
          options.bot.moonkin.password,
        )
          .then((response: string) => {
            if (response) {
              const customConfig = JSON.parse(response) as CustomConfig;
              options.placeholder.avatar.toast = {
                message: customConfig.kauzChatGreeting,
                placeholderTimeout: customConfig.kauzChatPlaceholderGreeting,
              };
              // custom styles
              options.placeholderStyles = customConfig.placeholderStyles;
              options.botAvatar = customConfig.botAvatar;

              setupBubble(options, baseUrl, embedUrl);
            } else {
              console.error('Configuration problem.');
            }
          })
          .catch((e) => {
            console.error('Configuration problem.');
            console.error(e.message || e);
          });
      } else if (options.multiClientSupport && !group) {
        console.error('Configuration problem. Group have to be provided.');
      } else {
        setupBubble(options, baseUrl, embedUrl);
      }
    })
    .catch(console.log);
}

try {
  const currentScript = document.currentScript.getAttribute('src');
  initChat(currentScript);
} catch (e) {
  console.log(e.message);
}

export default {
  initChat,
};
